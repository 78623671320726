import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UpdateLanguageDialog } from '../dialogs/update-language-dialog/update-language-dialog.component';
import { PDFDialogComponent } from '../dialogs/pdfgen-dialog/pdfgen-dialog.component';
import { DeleteDialogComponent } from '../dialogs/delete-dialog/delete-dialog.component';
import { UpdateMyAffiliationsComponent } from '../dialogs/update-my-affiliations/update-my-affiliations.component';
import { Affiliation, Visit } from '../api/generated/graphql';
import { UpdateVisitComponent } from '../dialogs/update-visit/update-visit.component';
import { DataOverwriteWarningComponent } from '../dialogs/data-overwrite-warning/data-overwrite-warning.component';
import { TranslatableContent } from '../components/translator/translator.component';
import { RequestTranslationComponent } from '../dialogs/request-translation/request-translation.component';
import { Observable } from 'rxjs';
import { ProfileDialogComponent } from '../dialogs/profile-dialog/profile-dialog.component';
import { ProfileService } from './profile/profile.service';
import { ErrorDialogComponent } from '../dialogs/error-dialog/error-dialog.component';
import { AddTimelineEntryComponent } from '../dialogs/add-timeline-entry/add-timeline-entry.component';
import { AddDiagnosticsEntryComponent } from '../dialogs/add-diagnostics-entry/add-diagnostics-entry.component';
import { MessageDialogComponent } from '../dialogs/message-dialog/message-dialog.component';
import { SaveRevisionDialogComponent } from '../dialogs/save-revision-dialog/save-revision-dialog.component';
import { MigratorDialogComponent } from '../dialogs/migrator-dialog/migrator-dialog.component';
import { MigrateInfoDialogComponent } from '../dialogs/migrate-info-dialog/migrate-info-dialog.component';

const minWidth = '300px';
const maxWidth = '500px';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(
    public dialog: MatDialog,
    private profileService: ProfileService,
  ) { }

  openPDFDialog(reportID: string): void {
    this.dialog.open(PDFDialogComponent, {
      minWidth: minWidth,
      width: '80vw',
      maxWidth: maxWidth,
      restoreFocus: false,
      autoFocus: false,
      scrollStrategy: new NoopScrollStrategy(),
      data: {
        reportId: reportID,
      }
    });
  }

  saveRevisionDialog(reportID: string): void {
    this.dialog.open(SaveRevisionDialogComponent, {
      minWidth: minWidth,
      width: '80vw',
      maxWidth: maxWidth,
      restoreFocus: false,
      autoFocus: false,
      scrollStrategy: new NoopScrollStrategy(),
      data: reportID,

    });
  }

  openUpdateLanguageDialog(): void {
    this.dialog.open(UpdateLanguageDialog, {
      minWidth: minWidth,
      width: '80vw',
      maxWidth: maxWidth,
      restoreFocus: false,
      scrollStrategy: new NoopScrollStrategy(),
    });
  }

  deleteDialog(reportID: string, title: string): void {
    this.dialog.open(DeleteDialogComponent, {
      minWidth: minWidth,
      width: '80vw',
      maxWidth: maxWidth,
      restoreFocus: false,
      data: {
        reportID: reportID,
        title: title,
      }
    })
  }

  closeDialog(): void {
    this.dialog.closeAll()
  }

  openUpdateMyAffilitions(affiliations: Affiliation[]) {
    this.dialog.open(UpdateMyAffiliationsComponent, {
      minWidth: minWidth,
      width: '70vw',
      maxWidth: '70vw',
      restoreFocus: false,
      autoFocus: false,
      data: {
        affs: affiliations,
      }
    })
  }

  openUpdateVisit(visit: Visit, reportID: string) {
    this.dialog.open(UpdateVisitComponent, {
      minWidth: minWidth,
      width: '70vw',
      maxWidth: '70vw',
      restoreFocus: false,
      autoFocus: false,
      data: {
        visit: visit,
        reportId: reportID,
      }
    })
  }

  openNewTimeline(reportID: string) {
    this.dialog.open(AddTimelineEntryComponent, {
      minWidth: minWidth,
      maxWidth: '70vw',
      restoreFocus: false,
      autoFocus: false,
      data: {
        reportId: reportID,
      }
    })
  }

  openNewDiagnostic(reportID: string) {
    this.dialog.open(AddDiagnosticsEntryComponent, {
      minWidth: '814px',
      maxWidth: '70vw',
      restoreFocus: false,
      autoFocus: false,
      data: {
        reportId: reportID,
      }
    })
  }

  openConfirmToEdit(reportId: string): Observable<any> {
    let dialogRef = this.dialog.open(DataOverwriteWarningComponent, {
      minWidth: minWidth,
      width: '50vw',
      maxWidth: '400px',
      restoreFocus: false,
      autoFocus: false,
      data: reportId
    })

    return dialogRef.afterClosed()

  }

  openTranslator(obj: TranslatableContent) {
    this.dialog.open(RequestTranslationComponent, {
      minWidth: minWidth,
      width: '50vw',
      maxWidth: '400px',
      minHeight: '250px',
      restoreFocus: false,
      autoFocus: false,
      data: obj
    })
  }

  openConfirmProfile(redirect_url: string) {
    this.dialog.open(ProfileDialogComponent, {
      disableClose: true,
      maxWidth: '500px',
      restoreFocus: false,
      scrollStrategy: new NoopScrollStrategy(),
      data: {
        user: this.profileService.getProfile(),
        redirect_url: redirect_url,
      }
    });
  }

  openErrorDialog(err: any) {
    this.dialog.open(ErrorDialogComponent, {
      disableClose: true,
      maxWidth: '500px',
      restoreFocus: false,
      scrollStrategy: new NoopScrollStrategy(),
      data: err,
    });
  }

  openMessageDialog(message: string): void {
    this.dialog.open(MessageDialogComponent, {
      disableClose: false,
      maxWidth: '500px',
      restoreFocus: false,
      scrollStrategy: new NoopScrollStrategy(),
      data: message,
    });
  }

  openMigratorDialog(reportID: string): void {
    this.dialog.open(MigratorDialogComponent, {
      disableClose: false,
      maxWidth: '500px',
      restoreFocus: false,
      scrollStrategy: new NoopScrollStrategy(),
      data: reportID
    });
  }

  openMigrateInfoDialog(): void {
    this.dialog.open(MigrateInfoDialogComponent, {
      disableClose: false,
      maxWidth: '650px',
      restoreFocus: false,
      scrollStrategy: new NoopScrollStrategy()
    });
  }
}
