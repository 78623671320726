import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-migrate-info-dialog',
  templateUrl: './migrate-info-dialog.component.html',
  styleUrls: ['./migrate-info-dialog.component.css']
})
export class MigrateInfoDialogComponent implements OnInit {

  constructor(
      public dialogRef: MatDialogRef<MigrateInfoDialogComponent>,
      public dialog: MatDialog,
      private router: Router
    ) { }

  ngOnInit(): void {
  }

  goToNew(): void {
    this.router.navigate(['/reports/new']);
  }

}
