<div mat-dialog-content>
    <h1>CARE-writer Version Upgrade</h1>

    <p>We're excited to announce that CARE-writer has moved to a new and improved platform—Version 3! This upgrade
        includes many enhancements designed to make writing case reports even easier.</p>

    <p>We encourage you to start using the new platform before creating your next case report. Your existing username
        and password will continue to work, and you can migrate any existing reports by visiting the settings page
        within each case report.</p>

    <p><strong>Important Dates:</strong></p>
    <ul>
        <li><strong>April 20:</strong> You will no longer be able to create new case reports on this version.</li>
        <li><strong>April 30:</strong> This version will officially become read-only.</li>
    </ul>ng

    <mat-divider></mat-divider>
    <br />
    <div class="flex-row">
        <button mat-button mat-dialog-close color="accent" (click)="goToNew()">
            Continue with Version 2
        </button>
        <a mat-flat-button color="primary" href="https://v3.care-writer.com" target="_blank" rel="noopener">
            Go To Version 3
        </a>
    </div>
</div>