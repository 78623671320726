import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, zip } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Report } from 'src/app/classes/report';
import { ReportService } from 'src/app/services/report/report.service';
import LogRocketErrorHandler from 'src/app/classes/logrocket-error';
import { ModalService } from 'src/app/services/modal.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  mergedReports$: Observable<Report[]> | null = null;
  error: any;
  
  constructor(
    public translate: TranslateService,
    private reportService: ReportService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    const reports: Observable<Report[]> = this.reportService.getReports().pipe(catchError(err => {
        this.error = err;
        console.error(err);
        let handler = new LogRocketErrorHandler;
        handler.handleError(err);
        return of([])
    }));

    const coReports: Observable<Report[]> = this.reportService.myCoAuthorReports().pipe(catchError(err => {
        this.error = err;
        console.error(err);
        let handler = new LogRocketErrorHandler;
        handler.handleError(err);
        return of([])
    }));
    
    this.mergedReports$ = zip(reports, coReports).pipe(
      map((data: any[]) => {
        const reps = data.reduce((acc, val) => acc.concat(val), []);
        return reps;
      }))
  }

  showDialog(): void {
    this.modalService.openMigrateInfoDialog();
  }
}
